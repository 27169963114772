import React from "react";
import HeroSection from "../landing/hero-section";
import ClientSection from "../landing/client-section";
import { PainPointsSection } from "../landing/pain-points-section";
import { ROICalculatorSection } from "../landing/roi-calculator-section";
import { SphereMask } from "../magicui/sphere-mask";
import PricingSection from "../landing/pricing-section";
import CallToActionSection from "../landing/cta-section";
import Particles from "../magicui/particles";

const Home = () => {
  return (
    <>
  
      <HeroSection />
      <ClientSection />
      <PainPointsSection />
      <ROICalculatorSection />
      <SphereMask />
      <PricingSection />
      <CallToActionSection />
      <Particles
        className="absolute inset-0 -z-10"
        quantity={50}
        ease={70}
        size={0.05}
        staticity={40}
        color={"#ffffff"}
      />
    </>
  );
};

export default Home;
