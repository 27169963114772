export function ROICalculatorSection() {
  return (
    <section className="relative w-full py-0 overflow-hidden">
      <div className="max-w-[1200px] mx-auto">
        {/* Title and Description */}
        <div className="flex flex-col items-center justify-center text-center m-0">
          {/* <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-white to-white/80 mb-4">
            The Graveyard of Lost Efforts:
          </h2> */}
        </div>
      </div>
    </section>
  );
}
