import { DiscordLogoIcon, TwitterLogoIcon } from "@radix-ui/react-icons";
import {Link} from "react-router-dom";
const footerNavs = [
  // {
  //   label: "Product",
  //   items: [
  //     {
  //       href: "/",
  //       name: "Email Collection",
  //     },
  //     {
  //       href: "/pricing",
  //       name: "Pricing",
  //     },
  //     {
  //       href: "/faq",
  //       name: "FAQ",
  //     },
  //   ],
  // },

  // {
  //   label: "Community",
  //   items: [
  //     {
  //       href: "/",
  //       name: "Discord",
  //     },
  //     {
  //       href: "/",
  //       name: "Twitter",
  //     },
  //     {
  //       href: "mailto:hello@chatcollect.com",
  //       name: "Email",
  //     },
  //   ],
  // },
  {
    label: "Legal",
    items: [
      {
        href: "https://wellstone.ai/terms-condition",
        name: "Terms",
      },

      {
        href: "https://wellstone.ai/privacy-policy",
        name: "Privacy",
      },
      {
        href: "mailto:support@wellstone.ai",
        name: "Contact",
      },
    ],
  },
];

const footerSocials= [
  // {
  //   href: "",
  //   name: "Discord",
  //   icon: <DiscordLogoIcon className="h-4 w-4" />,
  // },
  // {
  //   href: "",
  //   name: "Twitter",
  //   icon: <TwitterLogoIcon className="h-4 w-4" />,
  // },
];

export function SiteFooter() {
  return (
    <footer className="border-t border-neutral-200 dark:border-neutral-800">
      <div className="mx-auto w-full max-w-screen-xl xl:pb-2">
        <div className="grid grid-cols-1 md:grid-cols-2 px-8 py-16 gap-8 max-w-2xl mx-auto">
          {/* Company Info Column */}
          <div className="text-center">
            <Link
              href="/"
              className="inline-flex items-center gap-2 mb-6 justify-center"
            >
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                WELLSTONE AI
              </span>
            </Link>
            <p className="text-gray-500 dark:text-gray-400">
              Based in Palo Alto, California.
            </p>
          </div>

          {/* Navigation Columns */}
          {footerNavs.map((nav) => (
            <div key={nav.label} className="text-center">
              <h2 className="mb-6 text-sm tracking-tighter font-medium text-gray-900 uppercase dark:text-white">
                {nav.label}
              </h2>
              <ul className="gap-4 grid">
                {nav.items.map((item) => (
                  <li key={item.name}>
                    <Link
                      href={item.href}
                      className="cursor-pointer text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200 duration-200 font-[450] text-sm"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Bottom Bar */}
        <div className="flex flex-col items-center justify-center border-t border-neutral-200 dark:border-neutral-800 py-8 px-8 gap-4">
          <span className="text-sm text-center text-gray-500 dark:text-gray-400">
            © {new Date().getFullYear()}{" "}
            <Link
              href="/"
              className="hover:text-gray-900 dark:hover:text-gray-200"
            >
              Wellstone AI
            </Link>
            . All Rights Reserved.
          </span>
          <div className="flex space-x-5">
            {footerSocials.map((social) => (
              <Link
                key={social.name}
                href={social.href}
                className="text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200"
              >
                {social.icon}
                <span className="sr-only">{social.name}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}
