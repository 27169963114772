export default function ClientSection() {
  const firstRow = [
    "Private Equity",
    "Investment Banking",
    "Family Office",
    "Commercial Real Estate",
    "Private Credit",
  ];

  const secondRow = ["Venture Capital", "Asset Management", "Hedge Funds"];

  return (
    <section id="clients" className="mx-auto max-w-[80rem] px-6 md:px-8">
      <div className="py-14">
        <div className="mx-auto max-w-screen-xl px-4 md:px-8">
          <h2 className="text-center text-sm font-semibold tracking-wider text-gray-600 dark:text-gray-400">
            TRUSTED BY LEADING INVESTMENT FIRMS WITH $770B+ AUM
          </h2>
          <div className="mt-8 flex flex-col gap-4">
            <ul className="flex flex-wrap items-center justify-center gap-4 md:gap-6">
              {firstRow.map((category, index) => (
                <li
                  key={index}
                  className="group relative bg-gray-50 dark:bg-gray-900/50 px-5 py-2.5 transition-all"
                >
                  <div className="relative z-10">
                    <span className="text-sm font-medium tracking-wide text-gray-800 dark:text-gray-200 transition-colors group-hover:text-black dark:group-hover:text-white">
                      {category}
                    </span>
                  </div>
                  <div className="absolute inset-0 z-0 bg-gradient-to-r from-gray-100/0 via-gray-100/50 to-gray-100/0 dark:from-gray-800/0 dark:via-gray-800/50 dark:to-gray-800/0 opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                </li>
              ))}
            </ul>
            <ul className="flex flex-wrap items-center justify-center gap-4 md:gap-6">
              {secondRow.map((category, index) => (
                <li
                  key={index}
                  className="group relative bg-gray-50 dark:bg-gray-900/50 px-5 py-2.5 transition-all"
                >
                  <div className="relative z-10">
                    <span className="text-sm font-medium tracking-wide text-gray-800 dark:text-gray-200 transition-colors group-hover:text-black dark:group-hover:text-white">
                      {category}
                    </span>
                  </div>
                  <div className="absolute inset-0 z-0 bg-gradient-to-r from-gray-100/0 via-gray-100/50 to-gray-100/0 dark:from-gray-800/0 dark:via-gray-800/50 dark:to-gray-800/0 opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
