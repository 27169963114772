import React from "react";
import "./App.css";
import { ThemeProvider } from "./components/theme-provider";
import { SiteHeader } from "./components/site-header";
import { SiteFooter } from "./components/site-footer";
import Home from "./components/home/index";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="dark"
      disableTransitionOnChange
    >
      <SiteHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/terms-condition" element={<TermsCondition />} />{" "}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "} */}
      </Routes>
      <SiteFooter />
    </ThemeProvider>
  );
}

export default App;
