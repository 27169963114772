import { useState } from "react";
import { cn } from "../../lib/utils";

// Pain points data array remains the same
const painPoints= [
  // Private Equity pain points
  {
    id: 1,
    title: "Overstated EBITDA Add-Backs",
    category: "Private Equity",
    description:
      "Forensically audit EBITDA adjustments, including one-time costs that are overstated or misclassified.",
    deliverable:
      "A detailed EBITDA adjustment breakdown with suggested corrections for the IC memo.",
  },
  {
    id: 2,
    title: "Revenue Reliance on Few Customers",
    category: "Private Equity",
    description:
      "Analyze customer concentration risk and revenue stability across the customer base.",
    deliverable:
      "Customer concentration analysis with historical trends and risk mitigation strategies.",
  },
  {
    id: 3,
    title: "Unclear Competitive Advantage",
    category: "Private Equity",
    description:
      "Evaluate market positioning and sustainable competitive advantages.",
    deliverable:
      "Competitive landscape analysis with detailed SWOT assessment.",
  },
  {
    id: 4,
    title: "Hidden Liabilities in Legal Contracts",
    category: "Private Equity",
    description:
      "Review key contracts for potential risks and hidden obligations.",
    deliverable:
      "Contract risk assessment summary with highlighted areas of concern.",
  },
  {
    id: 5,
    title: "Unsustainable Earnings Trends",
    category: "Private Equity",
    description:
      "Cross-check earnings quality to ensure that revenue and profit trends are sustainable.",
    deliverable:
      "QoE report that highlights any issues with revenue recognition or unsustainable one-time income.",
  },
  {
    id: 6,
    title: "Covenant Breach Exposure",
    category: "Private Equity",
    description:
      "Assess compliance with debt covenants and potential breach risks.",
    deliverable: "Covenant compliance analysis with stress test scenarios.",
  },
  {
    id: 7,
    title: "Operational Inefficiencies",
    category: "Private Equity",
    description:
      "Identify operational bottlenecks and improvement opportunities.",
    deliverable:
      "Operational efficiency report with cost-saving recommendations.",
  },
  {
    id: 8,
    title: "Restrictive Employee Contract Liabilities",
    category: "Private Equity",
    description: "Review employment agreements and associated liabilities.",
    deliverable:
      "Employment contract risk assessment and mitigation strategies.",
  },
  {
    id: 9,
    title: "Missed Synergy Opportunities",
    category: "Private Equity",
    description: "Identify potential synergies across portfolio companies.",
    deliverable: "Synergy analysis report with implementation roadmap.",
  },
  {
    id: 10,
    title: "Unrealistic Exit Multiples",
    category: "Private Equity",
    description:
      "Validate exit assumptions and comparable transaction multiples.",
    deliverable: "Exit scenario analysis with market-based valuation metrics.",
  },

  // Private Credit pain points
  {
    id: 1,
    title: "Weak Debt Covenants",
    category: "Private Credit",
    description:
      "Analyze the strength of debt covenants and likelihood of breaches.",
    deliverable:
      "A covenant compliance report forecasting breach risks over time.",
  },
  {
    id: 2,
    title: "DSCR Underperformance",
    category: "Private Credit",
    description:
      "Evaluate debt service coverage ratios and cash flow adequacy.",
    deliverable: "DSCR analysis with stress testing scenarios.",
  },
  {
    id: 3,
    title: "Overstated Collateral Valuation",
    category: "Private Credit",
    description:
      "Verify collateral values and assess potential deterioration risks.",
    deliverable: "Collateral valuation report with market comparables.",
  },
  {
    id: 4,
    title: "Borrower Financial Instability",
    category: "Private Credit",
    description:
      "Assess borrower's financial health and ability to service debt.",
    deliverable: "Borrower credit analysis with financial projections.",
  },
  {
    id: 5,
    title: "Risky Loan Agreement Terms",
    category: "Private Credit",
    description:
      "Review loan terms for potential risks and enforcement issues.",
    deliverable:
      "Loan agreement analysis with risk mitigation recommendations.",
  },
  {
    id: 6,
    title: "Debt Restructuring Pitfalls",
    category: "Private Credit",
    description:
      "Identify potential challenges in debt restructuring scenarios.",
    deliverable: "Restructuring options analysis with implementation roadmap.",
  },
  {
    id: 7,
    title: "High Default Probability",
    category: "Private Credit",
    description: "Evaluate likelihood of default and recovery scenarios.",
    deliverable: "Default risk assessment with recovery analysis.",
  },
  {
    id: 8,
    title: "Upcoming Refinancing Stress",
    category: "Private Credit",
    description: "Assess refinancing risks and market conditions.",
    deliverable: "Refinancing risk analysis with market outlook.",
  },
  {
    id: 9,
    title: "Cross-Collateralization Vulnerabilities",
    category: "Private Credit",
    description: "Review cross-collateralization arrangements and risks.",
    deliverable: "Collateral structure analysis with risk assessment.",
  },
  {
    id: 10,
    title: "Liquidity Shortfalls",
    category: "Private Credit",
    description: "Identify potential liquidity gaps and funding needs.",
    deliverable: "Liquidity analysis with cash flow projections.",
  },

  // Real Estate pain points
  {
    id: 1,
    title: "Tenant Credit Exposure",
    category: "Real Estate",
    description:
      "Analyze the credit quality of major tenants, especially in multi-tenant properties.",
    deliverable:
      "A tenant risk memo highlighting tenant vulnerabilities or upcoming lease expirations.",
  },
  {
    id: 2,
    title: "Overestimated Rental Income",
    category: "Real Estate",
    description: "Validate rental income projections and market rates.",
    deliverable: "Market rent analysis with comparable property benchmarks.",
  },
  {
    id: 3,
    title: "Discrepancies in Cap Rate Valuations",
    category: "Real Estate",
    description:
      "Review capitalization rate assumptions and market comparables.",
    deliverable: "Cap rate analysis with market trend assessment.",
  },
  {
    id: 4,
    title: "Inflated Appraisal Values",
    category: "Real Estate",
    description: "Verify property appraisals against market data.",
    deliverable: "Appraisal review with value reconciliation.",
  },
  {
    id: 5,
    title: "Weak Lease Terms",
    category: "Real Estate",
    description: "Evaluate lease agreement terms and tenant obligations.",
    deliverable: "Lease analysis with term comparison matrix.",
  },
  {
    id: 6,
    title: "Underestimated CapEx Needs",
    category: "Real Estate",
    description: "Assess capital expenditure requirements and timing.",
    deliverable: "CapEx forecast with maintenance schedule.",
  },
  {
    id: 7,
    title: "Market Volatility Risks",
    category: "Real Estate",
    description: "Evaluate market cycle risks and potential value impacts.",
    deliverable: "Market cycle analysis with downside scenarios.",
  },
  {
    id: 8,
    title: "Over-Reliance on Key Tenants",
    category: "Real Estate",
    description:
      "Assess tenant concentration risks and diversification options.",
    deliverable: "Tenant diversification strategy with risk mitigation plan.",
  },
  {
    id: 9,
    title: "Future Vacancy Concerns",
    category: "Real Estate",
    description: "Project future vacancy rates and leasing challenges.",
    deliverable: "Vacancy analysis with leasing strategy recommendations.",
  },
  {
    id: 10,
    title: "Unstable Rent Roll",
    category: "Real Estate",
    description: "Review rent roll stability and tenant turnover patterns.",
    deliverable: "Rent roll analysis with historical trend assessment.",
  },

  // Family Office pain points
  {
    id: 1,
    title: "Multi-Generation Wealth Planning",
    category: "Family Office",
    description:
      "Develop comprehensive wealth transfer and succession planning strategies.",
    deliverable:
      "Multi-generation wealth preservation and transfer strategy document.",
  },
  {
    id: 2,
    title: "Tax Structure Inefficiencies",
    category: "Family Office",
    description:
      "Optimize tax structures across multiple jurisdictions and investment vehicles.",
    deliverable:
      "Tax optimization strategy with jurisdiction-specific recommendations.",
  },
  {
    id: 3,
    title: "Portfolio Company Governance",
    category: "Family Office",
    description:
      "Establish effective governance frameworks for family-owned businesses.",
    deliverable:
      "Governance framework with roles, responsibilities, and reporting structures.",
  },
  {
    id: 4,
    title: "Investment Strategy Alignment",
    category: "Family Office",
    description:
      "Ensure investment decisions align with family values and long-term objectives.",
    deliverable: "Investment policy statement with family-specific guidelines.",
  },
  {
    id: 5,
    title: "Risk Management Gaps",
    category: "Family Office",
    description:
      "Identify and address gaps in risk management across the portfolio.",
    deliverable: "Comprehensive risk assessment and mitigation plan.",
  },
  {
    id: 6,
    title: "Legacy Planning Challenges",
    category: "Family Office",
    description:
      "Address succession planning and next-generation engagement issues.",
    deliverable:
      "Succession planning roadmap with next-gen development strategy.",
  },
  {
    id: 7,
    title: "Philanthropic Impact Measurement",
    category: "Family Office",
    description:
      "Measure and optimize philanthropic initiatives and social impact.",
    deliverable:
      "Impact measurement framework with KPIs and reporting templates.",
  },
  {
    id: 8,
    title: "Family Communication Barriers",
    category: "Family Office",
    description:
      "Improve communication and decision-making processes among family members.",
    deliverable: "Family communication protocol and governance structure.",
  },
  {
    id: 9,
    title: "Investment Performance Tracking",
    category: "Family Office",
    description:
      "Enhance monitoring and reporting of investment performance across assets.",
    deliverable: "Consolidated performance dashboard with custom metrics.",
  },
  {
    id: 10,
    title: "Regulatory Compliance Oversight",
    category: "Family Office",
    description:
      "Ensure compliance with evolving regulations across jurisdictions.",
    deliverable: "Compliance monitoring system with regulatory updates.",
  },

  // Venture Capital pain points
  {
    id: 1,
    title: "Founder's Inexperience in Market",
    category: "Venture Capital",
    description:
      "Evaluate founder experience and alignment with market dynamics.",
    deliverable: "Founder background analysis and market fit assessment.",
  },
  {
    id: 2,
    title: "Product-Market Fit Uncertainty",
    category: "Venture Capital",
    description: "Validate product-market fit and customer adoption potential.",
    deliverable: "Market validation report with customer feedback analysis.",
  },
  {
    id: 3,
    title: "Technology Stack Vulnerabilities",
    category: "Venture Capital",
    description:
      "Assess scalability and security of the technology infrastructure.",
    deliverable: "Technical due diligence report with architecture review.",
  },
  {
    id: 4,
    title: "Customer Acquisition Economics",
    category: "Venture Capital",
    description: "Analyze CAC, LTV, and unit economics sustainability.",
    deliverable: "Unit economics analysis with growth projections.",
  },
  {
    id: 5,
    title: "IP Protection Gaps",
    category: "Venture Capital",
    description:
      "Evaluate intellectual property protection and competitive moats.",
    deliverable: "IP strategy assessment and protection recommendations.",
  },
  {
    id: 6,
    title: "Scaling Infrastructure Readiness",
    category: "Venture Capital",
    description: "Assess operational readiness for rapid scaling.",
    deliverable:
      "Scaling readiness report with infrastructure recommendations.",
  },
  {
    id: 7,
    title: "Cap Table Complications",
    category: "Venture Capital",
    description: "Review cap table structure and future dilution impacts.",
    deliverable: "Cap table analysis with dilution scenarios.",
  },
  {
    id: 8,
    title: "Regulatory Compliance Risks",
    category: "Venture Capital",
    description: "Identify regulatory risks in target markets.",
    deliverable: "Regulatory compliance roadmap and risk mitigation plan.",
  },
  {
    id: 9,
    title: "Team Composition Gaps",
    category: "Venture Capital",
    description: "Evaluate team capabilities and identify key hiring needs.",
    deliverable: "Team assessment and hiring plan recommendations.",
  },
  {
    id: 10,
    title: "Exit Strategy Viability",
    category: "Venture Capital",
    description: "Assess potential exit options and timeline feasibility.",
    deliverable: "Exit strategy analysis with comparable transactions.",
  },

  // Investment Banking pain points
  {
    id: 1,
    title: "Deal Pipeline Management",
    category: "Investment Banking",
    description:
      "Streamline deal flow and improve transaction execution efficiency.",
    deliverable: "Deal tracking system with milestone monitoring.",
  },
  {
    id: 2,
    title: "Valuation Model Complexity",
    category: "Investment Banking",
    description:
      "Simplify complex financial models while maintaining accuracy.",
    deliverable: "Streamlined valuation models with sensitivity analysis.",
  },
  {
    id: 3,
    title: "Due Diligence Bottlenecks",
    category: "Investment Banking",
    description:
      "Accelerate due diligence process without compromising quality.",
    deliverable: "Due diligence checklist with automated verification tools.",
  },
  {
    id: 4,
    title: "CIM Preparation Time",
    category: "Investment Banking",
    description: "Reduce time required for CIM creation and updates.",
    deliverable: "Automated CIM templates with industry-specific content.",
  },
  {
    id: 5,
    title: "Buyer List Generation",
    category: "Investment Banking",
    description: "Identify and prioritize potential buyers more effectively.",
    deliverable: "Targeted buyer list with strategic fit analysis.",
  },
  {
    id: 6,
    title: "Market Comp Analysis",
    category: "Investment Banking",
    description: "Streamline comparable company and transaction analysis.",
    deliverable: "Comprehensive comp analysis with market insights.",
  },
  {
    id: 7,
    title: "Deal Marketing Materials",
    category: "Investment Banking",
    description: "Create compelling marketing materials efficiently.",
    deliverable: "Customized marketing materials with industry positioning.",
  },
  {
    id: 8,
    title: "Transaction Structure Optimization",
    category: "Investment Banking",
    description: "Optimize deal structures for tax and strategic benefits.",
    deliverable: "Structure alternatives analysis with tax implications.",
  },
  {
    id: 9,
    title: "Synergy Quantification",
    category: "Investment Banking",
    description: "Accurately quantify and validate potential synergies.",
    deliverable: "Detailed synergy analysis with implementation timeline.",
  },
  {
    id: 10,
    title: "Post-Merger Integration Planning",
    category: "Investment Banking",
    description: "Develop comprehensive post-merger integration strategies.",
    deliverable: "Integration roadmap with key milestones and risks.",
  },

  // Add remaining categories with their respective pain points...
];

const categories = [
  "Private Equity",
  "Family Office",
  "Venture Capital",
  "Investment Banking",
  "Private Credit",
  "Real Estate",
];

function PainPointsSection() {
  const [selectedPoint, setSelectedPoint] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("Private Equity");

  const filteredPainPoints = painPoints
    .filter((point) => point.category === selectedCategory)
    .map((point, index) => ({
      ...point,
      displayId: index + 1,
    }));

  const selectedPainPoint = painPoints.find(
    (p) => p.category === selectedCategory && p.id === selectedPoint
  );

  return (
    <section className="relative w-full py-16 overflow-hidden bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center space-y-2 text-center mb-12">
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl pb-8 max-w-[900px] mx-auto">
            <span className="block bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/50 mb-6">
              Kill weak deals{" "}
              <span className="underline decoration-white/90 decoration-2 underline-offset-[6px] inline-block pb-1">
                fast
              </span>
            </span>
            <span className="block bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-white/50">
              Clear the right ones{" "}
              <span className="underline decoration-white/90 decoration-2 underline-offset-[6px] inline-block pb-1">
                in half the time
              </span>
            </span>
          </h2>
          <p className="max-w-[700px] mx-auto text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed mt-4">
            Top pains based on a survey across MM PE, IB, CRE, MFOs & more.
          </p>
        </div>

        <div className="flex flex-wrap justify-center gap-3 mt-8">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => {
                setSelectedCategory(category);
                const firstPointInCategory = painPoints.find(
                  (p) => p.category === category
                );
                if (firstPointInCategory) {
                  setSelectedPoint(firstPointInCategory.id);
                }
              }}
              className={cn(
                "px-4 py-2 rounded-full transition-all text-sm",
                "hover:bg-accent/50",
                selectedCategory === category
                  ? "bg-blue-500 text-white"
                  : "bg-accent/30 text-muted-foreground"
              )}
            >
              {category}
            </button>
          ))}
        </div>

        <div className="grid gap-8 mt-12 md:grid-cols-[60%_40%] max-w-[1200px] mx-auto px-4">
          <div>
            <div className="grid grid-cols-2 gap-3">
              {filteredPainPoints.map((point) => (
                <button
                  key={`${point.category}-${point.id}`}
                  onClick={() => setSelectedPoint(point.id)}
                  className={cn(
                    "w-full text-left p-2.5 rounded-lg transition-all",
                    "hover:bg-accent/50",
                    selectedPoint === point.id
                      ? "bg-accent ring-1 ring-blue-500"
                      : "bg-background"
                  )}
                >
                  <div className="flex items-start justify-between gap-2">
                    <div className="flex-1 min-w-0">
                      <h3 className="font-semibold text-sm truncate">
                        {point.title}
                      </h3>
                      <p className="text-xs text-muted-foreground mt-0.5 truncate">
                        {point.category}
                      </p>
                    </div>
                    <span className="text-xs font-medium text-emerald-500 bg-emerald-500/10 px-2 py-0.5 rounded-full shrink-0">
                      #{point.displayId}
                    </span>
                  </div>
                </button>
              ))}
            </div>
          </div>

          <div>
            <div className="p-4 rounded-lg bg-accent/50">
              {selectedPainPoint && (
                <div className="space-y-3">
                  <h3 className="text-lg font-semibold">
                    {selectedPainPoint.title}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    {selectedPainPoint.description}
                  </p>
                  <div className="mt-2 p-3 rounded-lg bg-blue-500/10 border border-blue-500/20">
                    <p className="text-sm">
                      <span className="font-semibold text-blue-500">
                        Deliverable:{" "}
                      </span>
                      {selectedPainPoint.deliverable}
                    </p>
                  </div>
                  <div className="mt-3 relative h-[200px] bg-black/90 rounded-lg overflow-hidden">
                    <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
                      <h4 className="text-lg font-semibold mb-1">
                        Password required
                      </h4>
                      <p className="text-sm text-muted-foreground mb-3">
                        If you have access, enter the password to watch.
                      </p>
                      <input
                        type="password"
                        placeholder="Enter password"
                        className="w-full max-w-md px-3 py-1.5 rounded-lg bg-background border border-accent mb-2"
                      />
                      <button className="w-full max-w-md px-3 py-1.5 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { PainPointsSection };