import { useState } from "react";

export const toHumanPrice = (price, decimals = 2) => {
  return Number(price / 100).toLocaleString("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

export default function PricingSection() {
  const [interval, setInterval] = useState("month");
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);

  const onSubscribeClick = async (priceId) => {
    setIsLoading(true);
    setId(priceId);
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate a delay
    setIsLoading(false);
  };

  return (
    <section id="pricing" className="bg-white dark:bg-transparent">
      <div className="mx-auto flex max-w-screen-xl flex-col gap-12 px-4 py-4 md:px-8">
        <div className="mx-auto max-w-3xl text-center">
          {/* <h1 className="text-4xl font-bold tracking-tight text-black dark:text-white sm:text-5xl">
            For Next Generation Dealmakers
          </h1> */}
        </div>
      </div>
    </section>
  );
}
